
import moment from 'moment';
import { defineComponent, PropType } from 'vue';
import { IRelease } from '../../entities/release';

export default defineComponent({
  computed: {
    formattedDate() {
      return moment.utc(this.release.publishedDate).fromNow();
    },
  },
  props: {
    release: {
      type: Object as PropType<IRelease>,
      required: true,
    },
  },
});
