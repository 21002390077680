
import { defineComponent } from 'vue';
import { mapWritableState } from 'pinia';
import { IRelease } from '../../entities/release';
import { getReleases } from '@/services/steamcord';
import { useMainStore } from '@/store/mainStore';
import DownloadCard from '@/components/dashboard/DownloadCard.vue';

export default defineComponent({
  components: {
    DownloadCard,
  },
  async beforeRouteEnter(to, from, next) {
    const releases = await getReleases();
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      (vm as any).releases = releases;
    });
  },
  computed: mapWritableState(useMainStore, ['loading']),
  data(): { releases: IRelease[] } {
    return {
      releases: [],
    };
  },
  mounted() {
    this.loading = false;
  },
});
